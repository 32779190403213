import { createContentfulClient } from '../../libs/contentful';
import { getServiceSiteFooterBanner } from '../index';
import Layout from '../../components/common/Layout';
import Meta from '../../components/common/Meta';
import Breadcrumb from '../../components/common/Breadcrumb';
import Kv from '../../components/section/app/Kv';
import Start from '../../components/section/app/Start';
import Careers from '../../components/section/common/Careers';

export default function CrispApp({ serviceSiteFooterBanner }) {
    let breadcrumbItems = [
        {
            key: 1,
            text: 'CRISP TOP',
            href: '/',
        },
        {
            key: 2,
            text: 'CRISP APP',
            href: '/app',
        },
    ];

    return (
        <Layout>
            <Meta
                title="CRISP APP ダウンロード│CRISP SALAD WORKS│クリスプ・サラダワークス"
                description="クレジットカード登録によるカンタンオーダー＆スムーズピックアップ、二度と思い出せないような複雑なカスタマイズもちゃんと注文履歴に残るからいつでも再オーダーが可能。そう、CRISP APPはあなたのサラダ体験をエキサイティングに変えるんです。"
            />
            <Breadcrumb items={breadcrumbItems} />
            <Kv />
            {/* <Start /> */}
            <Careers serviceSiteFooterBanner={serviceSiteFooterBanner} />
        </Layout>
    );
}

export const getStaticProps = async () => {
    const client = createContentfulClient();
    const serviceSiteFooterBanner = await getServiceSiteFooterBanner(client);

    return {
        props: {
            serviceSiteFooterBanner,
        },
    };
};
